@use '@angular/material' as mat;
@use 'app-theme.scss' as *;

:root {

    //   --mdc-elevated-card-container-shape: 6px;
    .mat-expansion-panel {
        &.summarized {
            .mat-expansion-panel-header {
                font-size: 13px;
                padding: 6px 16px;
                border-radius: 0;

                .mat-expansion-panel-header-title {
                    span {
                        font-weight: 100;
                        display: inline-block;
                        margin-right: 2px;
                    }
                }
            }

            &.mat-expanded {
                .mat-expansion-panel-header {
                    background: rgba(3, 64, 131, 0.05);
                }
            }

            .mat-expansion-panel-body {
                padding-top: 16px;
                font-size: 0.9rem;
                span {
                    font-weight: 100;
                }
            }
        }
    }

    .mdc-button.mdc-button-secondary {
        background-color: mat.get-theme-color($app-theme, secondary, 40);
    }

    .mat-accordion.panel-steps {
        mat-expansion-panel {
            border-radius: 5px !important;

            &:not(.mat-expanded),
            &:not(.mat-expansion-panel-spacing) {
                border-radius: 0 !important;
            }

            .mat-expansion-panel-header {
                pointer-events: none;
                padding: 0 16px;

                &.show-step-summary {
                    padding: 10px;
                }

                &:not([aria-disabled=true]) {
                    &.cdk-program-focused {
                        background: none !important;
                    }
                }

                .mat-content-hide-toggle {
                    margin-right: 0;

                    .mat-expansion-panel-header-title {
                        margin-right: 0;
                    }
                }

                .mat-expansion-panel-header-title {

                    .hidden {
                        display: none !important;
                    }

                    span {
                        font-weight: 100;
                        display: inline-block;
                        margin-right: 2px;
                    }

                    .summary {
                        font-size: 13px;
                        white-space: nowrap;
                    }
                }
            }

            .mat-expansion-panel-body {
                padding: 0 16px 16px;
            }

            .mat-action-row {
                padding: 8px;
            }
        }
    }

    .steps-navigation {
        position: sticky;
        bottom: 0;
        margin-top: 15px;
        display: flex;
        padding: 0;
        justify-content: space-between;

        &.align-center {
            justify-content: center;
        }

        .mdc-button {
            margin: 5px 10px;
        }
    }
}

.error {
    color: mat.get-theme-color($app-theme, error, 50);
}

.info-card {
    .mat-card-header {
        background-color: mat.get-theme-color($app-theme, primary, 50);
        padding: 5px 10px;
        border-radius: 10px 10px 0 0;
        color: #ffffff;

        .mat-mdc-card-title {
            font-size: 1rem;
        }
    }

    .mat-mdc-card-content {
        font-size: 0.9rem;
        padding: 10px;
    }
}

@media (min-width: 0px) and (max-width: 599px) {

    form.full-width,
    .form-group.full-width {
        .mat-form-field-invalid {
            padding-bottom: 10px;
        }

        mat-form-field, .form-field-container, wsx-autocomplete {
            width: 100%;
        }
    }
}

@media screen and (max-width: 430px) {
    .hide-mobile {
        display: none !important;
    }
}


app-important-info {
    .actions .hide-option {
        @include mat.checkbox-overrides((
            label-text-size: 12px,
            state-layer-size: 18px,
        ));
        .mdc-checkbox__background {
            width: 16px;
            height: 16px;
        }
    }
}